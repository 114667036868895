import { Line } from '@react-three/drei';
import { extend, ThreeEvent } from '@react-three/fiber';
import { useEffect, useMemo } from 'react';
import { BufferGeometry, Float32BufferAttribute, Shape, Vector2, Vector3 } from 'three';
import { LineGeometry } from 'three/examples/jsm/lines/LineGeometry';
import { EffectComposer } from 'three/examples/jsm/postprocessing/EffectComposer';
import { UnrealBloomPass } from 'three/examples/jsm/postprocessing/UnrealBloomPass';
import { ProfiledContourGeometry } from '../../util/ProfiledContourGeometry';
import { TunnelGeometry } from '../../util/TunnelGeometry';
import { NavMesh as NavMeshProps } from '../MapData/useMapData';
import { useMapInteraction } from '../MapInteraction/useMapInteraction';
import { MapPageArgs } from '../MapPage/MapPage';
import { linesFromNavmesh } from './utils';

extend({ EffectComposer, UnrealBloomPass });

export const NavMesh = ({
  navMesh,
  position,
  mode,
  onLineClick,
  onDoubleClick,
}: NavMeshProps & {
  mode?: MapPageArgs['mode'],
  onLineClick?: (e: ThreeEvent<MouseEvent>) => void,
  onDoubleClick?: (e: ThreeEvent<MouseEvent>) => void
}) => {
  const {
    settings: { generateTunnel, cinematicMode },
    level,
  } = useMapInteraction();

  // Random key that is regenerated whenever navmesh is changed or refreshed
  const meshKey = Math.random();

  const levelNavemsh = useMemo(() => ({
    ...navMesh,
    links: navMesh.links.filter(l => level === undefined || l.level === level)
  }), [navMesh, level])

  // Map the vector positions to the normalized navmesh link data
  const denormalizedLinks: [a: Vector3, b: Vector3, unnavigable: boolean][] = useMemo(() => {
    const nodes = Object.fromEntries(
      levelNavemsh.nodes.map((node): [number, Vector3] => [node.id, node.pos]),
    );
    return levelNavemsh.links.map(({ a, b, unnavigable }) => [nodes[a], nodes[b], !!unnavigable]);
  }, [levelNavemsh]);

  // Create an array of lines from the navmesh,
  // where new lines are created whenever the navmesh forks.
  const navMeshLines = useMemo(() => {
    return linesFromNavmesh(levelNavemsh);
  }, [levelNavemsh]);

  // Generate tunnel extrusions
  const tunnelGeometries = useMemo(() => {
    return navMeshLines.map(line => TunnelGeometry(line));
  }, [navMeshLines]);

  // Generate tunnel surface extrusions
  const surfaceGeometries = useMemo(() => {
    const shape = new Shape([
      new Vector2(-2.5, -0.1),
      new Vector2(-2.5, 0),
      new Vector2(2.5, 0),
      new Vector2(2.5, -0.1),
    ]);

    return navMeshLines.map(line => ProfiledContourGeometry(shape, line, false, true));
  }, [navMeshLines]);

  return (
    <group position={position}>
      {!generateTunnel && mode !== 'edit' &&
        denormalizedLinks.map(([a, b], i) => <Line key={i} points={[a, b]} color={'lightblue'} onDoubleClick={onDoubleClick} />)}
      {mode === 'edit' &&
        denormalizedLinks.map(([a, b, unnavigable], i) => <Line key={i} points={[a, b]} color={unnavigable ? 'red' : 'lightblue'} transparent opacity={generateTunnel ? 0.25 : 1} lineWidth={10} onClick={onLineClick} />)
      }
      {generateTunnel && !cinematicMode && (
        <>
          {tunnelGeometries.map((tunnel, i) => (
            <mesh key={`${level ?? 'all'}_tunnel_${i}_${meshKey}`} args={[tunnel]} receiveShadow={false} onDoubleClick={onDoubleClick}>
              <meshStandardMaterial color="#fff" opacity={0.1} transparent wireframe />
            </mesh>
          ))}
          {surfaceGeometries.map((tunnel, i) => (
            <mesh key={`${level ?? 'all'}_surface_${i}_${meshKey}`} args={[tunnel]} receiveShadow>
              <meshStandardMaterial color={'#A9927D'} />
            </mesh>
          ))}
        </>
      )}
      {generateTunnel && cinematicMode && (
        <>
          {tunnelGeometries.map((tunnel, i) => (
            <mesh key={`${level ?? 'all'}_tunnel_cinematic_${i}_${meshKey}`} args={[tunnel]} receiveShadow={false} onDoubleClick={onDoubleClick}>
              <meshStandardMaterial color="#aaf" opacity={0.3} transparent wireframe />
            </mesh>
          ))}
          {surfaceGeometries.map((tunnel, i) => (
            <mesh key={`${level ?? 'all'}_surface_cinematic_${i}_${meshKey}`} args={[tunnel]} receiveShadow>
              <meshStandardMaterial color={'#55f'} opacity={0.1} transparent />
            </mesh>
          ))}
        </>
      )}
    </group>
  );
};
