import { Object3D, Vector3 } from 'three';
import { CartesianCoordinates } from '../../util/Events/schema';

export const quaternionFromPositions = (from: Vector3, to: Vector3) => {
  if (from.distanceTo(to) < 0.5) return undefined;

  const mockObject = new Object3D();
  const dir = to.clone().sub(from).normalize();
  const beyondTo = to.clone();
  while (from.distanceTo(beyondTo) < 2) {
    beyondTo.add(dir);
  }
  mockObject.position.copy(from);
  mockObject.lookAt(beyondTo);
  return mockObject.quaternion;
};

// Based on https://stackoverflow.com/questions/58253469/how-to-determine-whether-a-point-is-in-between-two-others#answer-58255485
export const pointIsBetweenTwoOthers = (pToCheck: Vector3, [pA, pB]: [Vector3, Vector3]) => {
  if (pToCheck.equals(pA) || pToCheck.equals(pB)) return true;

  const distAtoB = pA.distanceTo(pB);
  const distAtoC = pA.distanceTo(pToCheck);
  const distBtoC = pB.distanceTo(pToCheck);
  if (distAtoC > distAtoB || distBtoC > distAtoB) return false;

  const vAtoB = pB.clone().sub(pA);
  const vBtoA = pA.clone().sub(pB);
  const vAtoC = pToCheck.clone().sub(pA);
  const vBtoC = pToCheck.clone().sub(pB);

  const angleAC = vAtoB.angleTo(vAtoC);
  const angleBC = vBtoA.angleTo(vBtoC);
  const epsilon = 0.0017; // 0.1° in radians
  if (Math.abs(angleAC) > epsilon || Math.abs(angleBC) > epsilon) return false;

  return true;
};

export const distanceViaPoints = (points: Vector3[] | undefined) =>
  points && points.length > 1
    ? points
        .map((vec, i) => (points[i + 1] ? vec.distanceTo(points[i + 1]) : 0))
        .reduce((a, b) => a + b)
    : undefined;

export const vector3ToCartesian = (vec: Vector3): CartesianCoordinates => {
  const [x, y, z] = vec.toArray();
  return { x, y, z };
};

// Retaining references to Vector3's prevent them from being garbage-collected,
// which can make the visualisation jerky if too much occurs at once
const vector3HoldingPen: Vector3[] = [];
export const temporaryVector3 = (x?: number, y?: number, z?: number) => {
  const v = vector3HoldingPen.pop() ?? new Vector3();
  v.set(x ?? 0, y ?? 0, z ?? 0);
  return v;
};
export const releaseVector3 = (...vectors: Vector3[]) => {
  vector3HoldingPen.push(...vectors);
};
