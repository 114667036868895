import { useFrame } from '@react-three/fiber';
import { RefObject, useEffect, useMemo, useRef } from 'react';
import { Group, Quaternion, Vector3 } from 'three';
import { CartesianCoordinates } from '../../../../../util/Events/schema';
import { clamp } from '../../../../../util/numberUtils';
import { findLinkAndNodes, findNavmeshPath } from '../../../../util/findNavmeshPath';
import { quaternionFromPositions, releaseVector3 } from '../../../../util/vectorUtils';
import { useMapData } from '../../../MapData/useMapData';
import { useSpeedAdjustment } from '../../../MapData/useSpeedAdjustment';

const DEFAULT_SLERP_DISTANCE = 1;

export type Segment = {
  node: {
    link: number;
    nodeA: Vector3;
    nodeB: Vector3;
  };
  length: number;
  startMetres: number;
  finishMetres: number;
  fromVector: Vector3;
  toVector: Vector3;
  quaternion?: Quaternion;
  previousQuaternion?: Quaternion;
  nextQuaternion?: Quaternion;
};

export const useInterpolatePosition = (
  maybePosition: CartesianCoordinates | undefined,
  groupRef: RefObject<Group>,
  speedMetrePerSec: number,
  setPath: (path: Vector3[]) => void,
  interpolate: boolean,
  id: string,
) => {
  const { multiplier, paused } = useSpeedAdjustment();

  const position = useMemo(() => {
    const group = groupRef.current;
    const isSomePosition =
      maybePosition?.x !== undefined &&
      maybePosition?.y !== undefined &&
      maybePosition?.z !== undefined;
    if (!group || !isSomePosition) return undefined;
    return new Vector3(maybePosition.x, maybePosition.y, maybePosition.z);
  }, [maybePosition?.x, maybePosition?.y, maybePosition?.z, groupRef]);

  const lerpRef = useRef<{
    current: Vector3;
    last: Vector3;
    deltaTotal: number;
    totalLengthMetres: number;
  }>({
    current: position ?? new Vector3(),
    last: position ?? new Vector3(),
    deltaTotal: 0,
    totalLengthMetres: 0,
  });

  const x = useMemo(() => position?.x, [position]);
  const y = useMemo(() => position?.y, [position]);
  const z = useMemo(() => position?.z, [position]);

  const segments = useRef<Array<Segment>>([]);

  const map = useMapData();

  useEffect(() => {
    if (
      !map?.data?.navMesh ||
      x === undefined ||
      y === undefined ||
      z === undefined ||
      !groupRef.current ||
      !lerpRef.current
    )
      return;

    lerpRef.current.current = new Vector3(x, y, z);
    lerpRef.current.last = groupRef.current.position.clone();
    lerpRef.current.deltaTotal = 0;
    lerpRef.current.totalLengthMetres = 0;

    const path =
      findNavmeshPath(map?.data?.navMesh, lerpRef.current.current, lerpRef.current.last) ?? [];

    releaseVector3(
      ...segments.current.flatMap(segment => [segment.node.nodeA, segment.node.nodeB]),
    );

    segments.current = [];
    for (let p = 0; p < path.length - 1; p++) {
      const linkAndNode = findLinkAndNodes(path[p], map?.data?.navMesh);
      const length = path[p].distanceTo(path[p + 1]);
      segments.current.push({
        node: linkAndNode,
        length: length,
        startMetres: 0,
        finishMetres: 0,
        fromVector: path[p],
        toVector: path[p + 1],
        quaternion: quaternionFromPositions(path[p], path[p + 1]),
      });
      lerpRef.current.totalLengthMetres += length;
    }

    segments.current.forEach((segment, i) => {
      const prevSegment: Segment | undefined = segments.current[i - 1];
      const nextSegment: Segment | undefined = segments.current[i + 1];
      segment.startMetres = prevSegment?.finishMetres ?? 0;
      segment.finishMetres = segment.startMetres + segment.length;
      segment.previousQuaternion = prevSegment?.quaternion;
      segment.nextQuaternion = nextSegment?.quaternion;
    });

    setPath(path);
  }, [x, y, z, groupRef, map?.data?.navMesh, setPath]);

  useFrame((_, delta) => {
    const group = groupRef.current;
    if (!group || !position || !interpolate || !segments.current.length || paused) return;

    lerpRef.current.deltaTotal += delta;
    const metresTravelled = clamp(speedMetrePerSec * multiplier * lerpRef.current.deltaTotal, {
      min: 0,
      max: lerpRef.current.totalLengthMetres,
    });
    const segment: Segment | undefined = segments.current.find(
      ({ startMetres: start, finishMetres: finish }) =>
        start < metresTravelled && metresTravelled <= finish,
    );
    if (!segment && lerpRef.current.totalLengthMetres > 0) {
      console.warn(
        `[${id}] Did not find segment for lerping! metresTravelled=${metresTravelled} segments=`,
        segments.current,
      );
    }
    const lerpPercentage = segment
      ? clamp(
          (metresTravelled - segment.startMetres) / (segment.finishMetres - segment.startMetres),
          { min: 0, max: 1 },
        )
      : 1;

    if (group && lerpRef && segment?.fromVector && segment?.toVector) {
      // Set rotation
      if (segment.quaternion) {
        const slerpDistance = Math.min(segment.length / 2, DEFAULT_SLERP_DISTANCE);
        const distanceFromStart = group.position.distanceTo(segment.fromVector);
        const distanceFromEnd = group.position.distanceTo(segment.toVector);
        const slerpFromPreviousSegment =
          distanceFromStart < slerpDistance && distanceFromStart < distanceFromEnd;
        const slerpToNextSegment = distanceFromEnd < slerpDistance;

        if (slerpFromPreviousSegment) {
          const slerpPrecentage = distanceFromStart / slerpDistance;
          group.quaternion.slerp(segment.quaternion, slerpPrecentage);
        } else if (slerpToNextSegment && segment.nextQuaternion) {
          const slerpPrecentage = 1 - distanceFromEnd / slerpDistance;
          group.rotation.setFromQuaternion(
            segment.quaternion.clone().slerp(segment.nextQuaternion, slerpPrecentage),
          );
        } else {
          group.quaternion.copy(segment.quaternion);
        }
      }

      // Set position
      group.position.lerpVectors(
        segment.fromVector,
        segment.toVector,
        !isNaN(lerpPercentage) ? lerpPercentage : 1,
      );
    }
  });
};
