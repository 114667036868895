// Configuration constants

export const BEACON_POSITIONS_BUFFER_MAX_SIZE = 1;
export const BEACON_RECENT_SECONDS = 300;
export const BEACON_OLD_LOCATION_SECONDS = 60;
export const CROSSING_RECENT_SECONDS = 60 * 60 * 2;
export const TRUCK_SPEED_KM_PER_HOUR = 20;
export const TRUCK_SPEED_METERS_PER_SECOND = (TRUCK_SPEED_KM_PER_HOUR * 1000) / 3600;
export const WALKING_SPEED_KM_PER_HOUR = 12; // was: 6.44;
export const WALKING_SPEED_METERS_PER_SECOND = (WALKING_SPEED_KM_PER_HOUR * 1000) / 3600;

export const DEFAULT_DEVICE_MODEL = 'Miners_Helmet';
export const DEFAULT_BEACON_MODEL = 'Mining_Truck';

export const EMS_ENABLED = true;

export const SAFE_DISTANCE_METRES = 20;

export const NOTIFICATION_AGE_LIMIT_MINUTES = 10;

export const NAVMESHBUILDER_DEFAULT_SMOOTHING = 3;
export const NAVMESHBUILDER_DEFAULT_SIMPLIFICATION = 60;
export const NAVMESHBUILDER_DEFAULT_LOCATOR_SPACING: [number, number] = [20, 100];
export const NAVMESHBUILDER_MIN_LOCATOR_SPACING_RANGE = 10;

export const DEFAULT_SCANNING_INTERVAL_SECONDS = 10;
export const DEFUALT_SCANNING_WINDOW_SECONDS = 5;
export const DEFAULT_OBS_LOOKBACK_SECONDS = 6;
export const DEFAULT_ENVIRONMENTAL_FACTOR = 20;
export const DEFAULT_CLAMPING_THRESHOLD_METRES = 5;

export const DEFAULT_INACTIVITY_THRESHOLD_MINUTES = 120;
export const MIN_INACTIVITY_THRESHOLD_MINUTES = 10;
export const MAX_INACTIVITY_THRESHOLD_MINUTES = 120;
